import * as React from "react"
import WorkItem from "./items/work-item";

const Work = (props) => {
    return (
        <section className={"section blue-section blue-section_pattern-v py-16 to-logo__white"}>
            <div className={"container"}>
                <h2 className={"mb-8"}>Quelques réalisations</h2>
                {props.projects.map((value, index) => {
                    return (
                        <WorkItem
                            key={index}
                            id={value.frontmatter.slug}
                            title={value.frontmatter.title}
                            path={value.fields.path}
                            description={value.frontmatter.description[0]}
                            tasks={value.frontmatter.tasks}
                            image={value.frontmatter.thumbnail}
                            scroll={value.frontmatter.scroll}
                        />
                    )
                })}
            </div>
        </section>
    );
}

export default Work;
