import * as React from "react"
import Button from "../../button";
import WorkItemThumb from "./work-item-thumb";
import LinkFade from "../../linkfade";

const WorkItem = (props) => {
    return (
        <section className={"work"}>
            <div className={"work-thumb"}>
                <LinkFade url={props.path}>
                    <WorkItemThumb image={props.image} scroll={props.scroll}/>
                </LinkFade>
            </div>
            {
                !props.only_thumb && (
                    <div className={"work-content"}>
                        <LinkFade url={props.path}>
                            <h3>{props.title}</h3>
                        </LinkFade>
                        <div className={"tags"}>
                            {props.tasks.map((v, i) => (
                                <div key={i} className={"tag"}>{v}</div>
                            ))}
                        </div>
                        <p>{props.description}</p>
                        <Button name={"En savoir plus"} link={props.path}/>
                    </div>
                )
            }
        </section>
    );
}

export default WorkItem;
