import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { graphql } from 'gatsby'
import "../scss/style.scss";
import {buildSeo} from "../utils/seo";
import Awards from "../components/section/awards";
import Button from "../components/button";
import Work from "../components/section/work";
import {withPrefix} from "gatsby";
import Sticker from "../components/sticker";
import LinkFade from "../components/linkfade";
const signal = require('signal-js/src/index').default;

// markup
const IndexPage = ({transitionStatus, data}) => {
  let textBlock = useRef();
  let exitThumbContainerButton = useRef();

  useEffect(() => {
    if(transitionStatus === 'entering') {
    }
    if(transitionStatus === 'exiting') {
      gsap.to(textBlock.current, {x: "-100%"});
    }
  }, [transitionStatus]);

  useEffect(() => {
    const indexThumbEvent =  () => {
      textBlock.current != null  && textBlock.current.classList.add('reduced');
      gsap.to(exitThumbContainerButton.current, {opacity: 1, duration: 0.2});
    };
    const indexThumbOutEvent = () => {
      textBlock.current != null  && textBlock.current.classList.remove('reduced');
      gsap.to(exitThumbContainerButton.current, {opacity: 0, duration: 0.2});
    };

    signal.on('thumb', indexThumbEvent);
    signal.on('thumb_out', indexThumbOutEvent);

    return function cleanup() {
      signal.off('thumb', indexThumbEvent);
      signal.off('thumb_out', indexThumbOutEvent);
    };

  },  []);

  return (
      <>
        {buildSeo({
          title: 'Loris, développeur web & designer freelance sur Annecy & Genève',
          noTitle: true,
          metaTitle: 'Loris Pinna, Développeur & designer freelance, Annecy (74)',
          description: "Développeur front-end & designer freelance vivant à Annecy, Loris est diplomé de l'école des Gobelins en Design et Management de l'innovation intéractive.",
          metaDescription: "Développeur front-end & designer freelance sur Annecy (Haute-Savoie) et Genève, créateur de site web, e-commerce ou vitrine depuis 5 ans."
        })}
        <Sticker name={"gobelins"}/>
        <div className={"page-content contact"}>
          <Sticker name={"antman"}/>

          <section className={"container to-logo__blue"}>
            <div className={"light light-yellow"}></div>
            <div className={"light light-purple"}></div>
            <div className={"background-title-container background-title-container__left as-title as-marquee"}>
              <div className={"background-title"}>
                <span>Salut ! Salut ! Salut ! Salut ! Salut ! Salut ! Salut ! Salut !&nbsp;</span>
                <span>Salut ! Salut ! Salut ! Salut ! Salut ! Salut ! Salut ! Salut !&nbsp;</span>
              </div>
            </div>
            <h1 className={"h2 md:max-w-66"}>Je suis Loris, développeur front-end & designer freelance basé sur Annecy & Genève.</h1>
            <div className={"mini-cta"}>
              <p>Je suis  <span className={"label"}>disponible</span> tout de suite pour vos missions, pour créer site web mémorable, qui émerveille vos clients 🌻.</p>
              <Button link={"/contact/"} name={"On prend contact ?"}/>
            </div>
           </section>


          <section className={"container to-logo__white"}>
            <Sticker name={"wishing_star"}/>
            <div className={"bg-blue infinite-left-blue text-white md:max-w-66 py-16 pr-4 shadow mb-8 worked-for"}>
              <span className={"text-metropolis text-24 font-bolder"}>J'AI TRAVAILLÉ POUR</span>
              <div className={"logo-list"}>
                <img src={withPrefix('/images/logo/agence-web-annecy-mdn.svg')}  alt={"Logo de MDN, agence web à Annecy"}/>
                <img src={withPrefix('/images/logo/pokexp-pokemon-fangame.svg')}  alt={"Logo of PokExp, fangame Pokémon"}/>
                <img src={withPrefix('/images/logo/hbh-logo.svg')} className={"square"} alt={"Logo de HBH, agence de communication"}/>
              </div>
            </div>
          </section>


          <section className={"container py-16 to-logo__blue"}>
            <h2 className={"mb-8"}>Je peux réaliser...</h2>
            <div className={"service-grid"}>
              <div className={"service-case"}>
                <div className={"service-item"}>
                  <Sticker name={"github"}/>
                  <span className={"service-item-tell"}>"le dev"</span>
                  <h3><LinkFade url={"/prestations/"}>Développement</LinkFade></h3>
                  <p>Une boutique à lancer ? Un site à développer ? Des modifications à apporter à votre site web actuel ? Nous travaillerons ensemble pour définir ce qu'il y a à faire !</p>
                  <ul>
                    <li><LinkFade url={"/prestations/prestashop/"}>Développement de modules & thèmes Prestashop</LinkFade></li>
                    <li><LinkFade url={"/prestations/e-commerce/"}>Développement & configuration Wordpress</LinkFade></li>
                    <li>Développement sur mesure & React</li>
                    <li>Intégration de maquettes</li>
                    <li>Maintenances & correction de bugs</li>
                  </ul>
                  <Button link={"/contact/"} name={"Contactez-moi"}/>
                </div>
              </div>
              <div className={"service-case"}>
                <div className={"service-item"}>
                  <span className={"service-item-tell"}>"le design"</span>
                  <h3><LinkFade url={"/prestations/"}>UI & UX</LinkFade></h3>
                  <p>Une refonte visuelle à apporter, avec un nouveau design, de nouvelles couleurs ? Une envie de revoir l'expérience utilisateur de votre site ? Trouvons les bonnes solutions ensemble !</p>
                  <ul>
                    <li>Conception & expérience utilisateur</li>
                    <li>Création de maquettes</li>
                    <li>Création de logo</li>
                  </ul>
                  <Button link={"/contact/"} name={"Contactez-moi"}/>
                </div>
              </div>
              <div className={"service-case"}>
                <div className={"service-item"}>
                  <span className={"service-item-tell"}>"la forma"</span>
                  <h3><LinkFade url={"/prestations/"}>Formation<br/>& assistance</LinkFade></h3>
                  <p>Des correctifs à apporter à votre projet ? Un suivi sur le long terme d'une application ? Une volontée de se former pour maitriser son site ? Je peux vous apporter mon aide.</p>
                  <Button link={"/contact/"} name={"Contactez-moi"}/>
                </div>
              </div>
            </div>
            <Sticker name={"pokexp"}/>
          </section>

          <Sticker name={"duck"}/>
          <Work projects={data.projects.nodes}/>
          <Sticker name={"cheese"}/>


          <section className={"container py-16 to-logo__blue"}>
            <Sticker name={"sunflower"}/>
            <div className={"big-cta"}>
              <div className="light light-yellow"></div>
              <div className={"background-title-container"}>
                <span className={"background-title pb-8 mobile-hide"}>On discute ?</span>
                <h2>Vous voulez un site<br/>
                  qui émerveille vos clients ?</h2>
                <p>Si ça vous dit, on peut discuter de votre projet en détails, et je vous ferais un devis sur mesure.</p>
                <Button link={'/contact/'} name={'Dites m\'en plus ici 👋'}/>
              </div>
            </div>
          </section>

          <Awards/>

          <section className={"section-center py-16"}>
              <h2 className={"mb-8 md:mb-16"}>Ils ont été comblés !</h2>
            <div className={"card-list"}>
              <div>
                <div className={"card-item"}>
                  <blockquote>Loris est rapide et efficace. Très professionnel. Il sait vulgariser les choses pour que l'on comprenne les tenants et aboutissants de nos demandes et les adapter au mieux. Il est force de proposition, pour aller plus loin dans la mission qu'on lui a confié. </blockquote>

                  <div className={"card-body"}>
                    <p>Thibault,<br/>gérant de EasySlackline</p>
                  </div>
                </div>
                <div className={"card-item"}>
                  <blockquote>J’ai adoré travailler avec Loris, il a été très à l’écoute de mes besoins pour mon portfolio. La gestion du projet s’est fait avec organisation, rapidité et respect! Je recommande à 100%</blockquote>
                  <div className={"card-body"}>
                    <p>Anselme,<br/>artiste en stop-motion</p>
                  </div>
                </div>
                <div className={"card-item"}>
                  <blockquote>Loris a su répondre entièrement à mes besoins et a été très à l’écoute. Très professionnel, je lui referai confiance sans hésiter pour mes prochains projets. Merci Loris !</blockquote>
                  <div className={"card-body"}>
                    <p>Chloé,<br/>graphiste freelance</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
  )
}

export default IndexPage


export const query = graphql`
    query { 
        projects: allMdx(
          filter: { frontmatter: {type: {eq: "projects"}, show_homepage: {eq: true}} },
          sort: { fields: [frontmatter___order], order: ASC }
          limit: 4
        ) {
          nodes {
            fields {
              path
            }
            frontmatter {
              slug
              thumbnail
              description
              scroll
              title
              tags
              tasks
              technos
            }
          }
        }  
    }`;
